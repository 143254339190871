@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://api.fontshare.com/v2/css?f[]=satoshi@700,500,300,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

.h-full {
  height: 100%;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: url(//triompher.ancorathemes.com/wp-content/uploads/2017/05/slider_2.jpg); */
  background: url(//triompher.ancorathemes.com/wp-content/uploads/2017/05/slider_1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  filter: brightness(100%);
}

.subpage-hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: url(//triompher.ancorathemes.com/wp-content/uploads/2017/05/slider_2.jpg); */
  background: url(//triompher.ancorathemes.com/wp-content/uploads/2017/05/slider_1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  filter: brightness(100%);
}